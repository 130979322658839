import type { FC } from 'react';
import React, { useMemo, useEffect, useState } from 'react';
import { BackTop, Popover } from 'antd';
import Icon from '@ant-design/icons';
import Image from 'next/image';
import Link from '@/components/Link';
// import { openChat } from '@/utils/chatSDK';
import { officialTel } from '@/utils/static';
import { isMacOS } from '@/utils/browser';
import Top from './assets/icon_fix-float_top.svg';
import center from './assets/icon-center.png';
import classNames from 'classnames';
import adSidebar from '/public/images/advertisement/pic_market_ad6@2x.png';
import styles from './index.module.less';
import { useRouter } from 'next/router';
import { track } from '@/utils/track';
import { userModelContainer } from '@/store/userModel';
import WechatCode from './WechatCode';

export interface ConsultProps {
  zIndex?: number;
  // 隐藏体验中心入口
  hiddenExperienceIcon?: boolean;
  bottom?: number;
  //隐藏折扣入口
  hiddenDiscountIcon?: boolean;
}

/**
 *
 * @name 咨询组建
 */
const Consult: FC<ConsultProps> = ({
  zIndex,
  hiddenExperienceIcon = false,
  hiddenDiscountIcon,
  bottom = 144,
}) => {
  const { userInfo } = userModelContainer.useContainer();
  const [style, setStyle] = useState<any>();
  const [adStatus, setAdStatus] = useState<boolean>();
  const router = useRouter();
  const phoneDes = useMemo(() => {
    return officialTel.split('-').map((item, index, arr) => {
      if (index !== arr.length - 1) {
        return (
          <React.Fragment key={item}>
            <span>{item}</span>
            <span className={styles.split}>-</span>
          </React.Fragment>
        );
      }
      return <span key={item}>{item}</span>;
    });
  }, [officialTel]);

  const JumpRouter = () => {
    if (router.asPath.includes('document/faq')) {
      track({
        name: '广告点击',
        keyword: '帮助文档——边栏广告——点击',
        userId: userInfo ? userInfo.openId : '',
      });
      window.open(`/market/list`, '_blank');
    } else if (router.asPath.includes('/document/')) {
      track({
        name: '广告点击',
        keyword: 'API文档——边栏广告——点击',
        userId: userInfo ? userInfo.openId : '',
      });
      window.open(
        `/market/detail/${router?.query?.service || 'text_recognize_3d1'}#Specifications`,
        '_blank',
      );
    } else if (router.asPath.includes('/experience/')) {
      track({
        name: '广告点击',
        keyword: '体验中心——边栏广告——点击',
        userId: userInfo ? userInfo.openId : '',
      });
      window.open(`/market/detail/${router?.query?.service}#Specifications`, '_blank');
    }
  };
  const onClose = () => {
    setAdStatus(false);
  };
  const content = (
    <div className={styles.tipContent}>
      <div className={styles.hoverBox}>
        {/* <div className={styles.messageHoverBox} onClick={openChat}>
          <div className={styles.icon}>
            <div className={styles.messageIcon} />
            <span className={styles.circular} />
          </div>
          <div className={styles.contentText}>
            <span className={styles.titleText}>在线人工客服</span>
            <span className={styles.hoverText} style={{ color: '#1a66ff' }}>
              专业商务经理在线解答
            </span>
          </div>
        </div> */}
        <div className={styles.phoneHoverBox}>
          <div className={styles.icon}>
            <div className={styles.phoneIcon} />
          </div>
          <div className={styles.contentText}>
            <span className={styles.titleText} style={style}>
              电话咨询
            </span>
            <span className={styles.hoverText}> {phoneDes}</span>
          </div>
        </div>
        <div className={styles.editHoverBox}>
          <Link href="/contact" passHref>
            <div className={styles.icon}>
              <div className={styles.editIcon} />
            </div>
            <div className={styles.contentText}>
              <div className={styles.titleText}>咨询留言</div>
              <div className={styles.hoverText}>
                <span className={styles.index}>1.</span>
                <span>产品使用问题反馈</span>
              </div>
              <div className={styles.hoverText}>
                <span className={styles.index}>2.</span>
                <span>批量采购购买、私有化部署咨询</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );

  const onVisibleChange = (open) => {
    if (open) {
      track({ name: '按钮点击', keyword: `侧边栏【官方社群】` });
    }
  };

  return (
    <div id="consult-slide" className={styles.wrapper} style={{ zIndex, bottom: bottom }}>
      {adStatus && (
        <>
          <div className={styles.centerWrapDic}>
            <span className={styles.closeIcon} onClick={onClose} />
            <div onClick={JumpRouter}>
              <Image src={adSidebar} width={70} height={70} alt="" />
            </div>
          </div>
        </>
      )}

      <div className={classNames(styles.centerWrap, { [styles.noCenter]: hiddenExperienceIcon })}>
        <WechatCode />

        {!hiddenExperienceIcon && (
          <div
            className={styles.center}
            onClick={() => {
              window.open('/experience/list');
            }}
          >
            <div className={styles.icon}>
              <Image unoptimized src={center} width={24} height={24} alt="" />
            </div>
            <div className={styles.textWrapper}>
              <span>体验中心</span>
            </div>
          </div>
        )}
      </div>

      <Popover placement="leftBottom" content={content} overlayClassName={styles.popoverPop}>
        <div className={styles.bottomBox}>
          <div className={styles.parentBox}>
            <div className={styles.contactBtn}>
              <p>联系我们</p>
            </div>
          </div>
        </div>
      </Popover>

      {/* <div className={styles.contactWrapper}>
        <div className={styles.contact} onClick={openChat}>
          <div className={styles.icon}>
            <Icon component={Message} className={styles.messageIcon} />
          </div>
          <div className={styles.hoverBox}>
            <Icon component={Message} />
            <span className={styles.hoverText}>人工客服</span>
          </div>
        </div>
        <div className={styles.contact}>
          <Link href="/contact" passHref>
            <div className={styles.icon}>
              <Icon component={Edit} />
            </div>
            <div className={styles.hoverBox}>
              <Icon component={Edit} />
              <span className={styles.hoverText}>商务留言</span>
            </div>
          </Link>
        </div>
        <div className={classNames(styles.contact, styles.phone)}>
          <div className={styles.icon}>
            <Icon component={Phone} />
          </div>
          <div className={styles.hoverBox}>
            <Icon component={Phone} />
            <span className={styles.hoverText} style={style}>
              {phoneDes}
            </span>
          </div>
        </div>
      </div> */}
      <BackTop style={{ right: 0, bottom: 80, width: 48, height: 48 }}>
        <div className={styles.backTop}>
          <div className={styles.icon}>
            <Icon component={Top} />
          </div>
        </div>
      </BackTop>
    </div>
  );
};

export default Consult;
